import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  VStack, FormControl, FormLabel, Input, Button, Alert, AlertIcon, InputGroup, InputRightElement, IconButton, Box, Spinner
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import axios from 'axios';

function ResetPasswordForm() {
  const { token } = useParams();  // Get the token from the URL
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);  // Loading state for GET request
  const [isTokenValid, setIsTokenValid] = useState(false);
  const navigate = useNavigate();

  // Verify the reset token when the component mounts
  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.get(`/api/reset/${token}`);
        console.log('Token verification response:', response);
        if (response.status === 200) {
          setIsTokenValid(true);  // Token is valid
          console.log('Token is valid');  // Log when token is valid
        }
      } catch (error) {
        setError('Invalid or expired token.');
        setIsTokenValid(false);
      } finally {
        setLoading(false);  // Stop loading
      }
    };

    verifyToken();
  }, [token]);

  // Handle resetting the password (POST request)
  const handleResetPassword = async () => {
    try {
      const response = await axios.post(`/api/reset/${token}`, { password });
      setSuccess(true);
      setError(null);

      // Redirect to home after successful password reset
      setTimeout(() => {
        navigate('/');
      }, 2000);  // Redirect after 2 seconds
    } catch (error) {
      setError('Error resetting password.');
      setSuccess(false);
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"  // Vertically center the form
    >
      <VStack spacing={4} p={5} align="center">
        {/* Show spinner while loading */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            {!isTokenValid ? (
              // Show error message if the token is invalid
              <Alert status="error">
                <AlertIcon />
                {error || 'Invalid or expired token.'}
              </Alert>
            ) : (
              // Show form if token is valid
              <>
                <FormControl id="password" isRequired>
                  <FormLabel>New Password</FormLabel>
                  <InputGroup maxW="400px">
                    <Input
                      type={showPassword ? "text" : "password"}  // Toggle between text and password
                      placeholder="Enter new password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement>
                      <IconButton
                        variant="ghost"
                        aria-label={showPassword ? "Hide password" : "Show password"}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}  // Change icon based on state
                        onClick={togglePasswordVisibility}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                {/* Show error message if the password reset fails */}
                {error && (
                  <Alert status="error">
                    <AlertIcon />
                    {error}
                  </Alert>
                )}

                {/* Show success message after password reset */}
                {success && (
                  <Alert status="success">
                    <AlertIcon />
                    Password has been reset successfully. Redirecting to home...
                  </Alert>
                )}

                <Button colorScheme="blue" onClick={handleResetPassword} isDisabled={success}>
                  Reset Password
                </Button>
              </>
            )}
          </>
        )}
      </VStack>
    </Box>
  );
}

export default ResetPasswordForm;
