import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, VStack, HStack, Text, Box, Table, Thead, Tbody, Tr, Th, Td, useToast, SimpleGrid, Divider } from '@chakra-ui/react';

function ResultsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();


  // Retrieve the company information and response data from location.state
  const companyInfo = useMemo(() => location.state?.companyInfo || [], [location.state]);
  const responseData = useMemo(() => {
    return location.state?.responseData || [];
  }, [location.state]);

  const columnMapping = {
    CompanyName: 'Company_Name',
    SmelterIdentification: 'Smelter_CID',
    Metal: 'Metal',
    SmelterLookup: 'Smelter_Name',
    RMAP_Audit_Status: 'RMAP_Audit_Status',
    Supplier_Feedback: 'Supplier_Feedback',
    Primary_Contact_Name: 'Smelter_Primary_Contact_Name',
    Primary_Contact_Email: 'Smelter_Primary_Contact_Email',
    Primary_Contact_Phone: 'Smelter_Primary_Contact_Phone',
    General_Contact_Name: 'Smelter_General_Contact_Name',
    General_Contact_Email: 'Smelter_General_Contact_Email',
    General_Contact_Phone: 'Smelter_General_Contact_Phone',
    Last_Audit_Date: 'Last_Audit_Date',
    Audit_Cycle: 'Audit_Cycle',
    Reaudit_In_Progress: 'Reaudit_In_Progress',
    Audit_Progress: 'Audit_Progress',
    Red_Flag: 'Red_Flag_Country',
    Direct_Sourcing: 'Direct_Sourcing',
    Indirect_Sourcing: 'Indirect_Sourcing',
    SmelterCountry: 'Smelter_Country',
    Verdict: 'Sanction_Verdict',
    Country: 'Sanction_Country',
    Smelter_Facility_Location_State_Province: 'Sanction_Smelter_Facility_Location_State_Province',
    Notes: 'Notes',
    RMAP_Eligibility: 'RMAP_Eligibility',
    Operational_Status: 'Operational_Status',
    Research_Notes: 'Research_Notes',
    Conflict_Minerals_Policy: 'Conflict_Minerals_Policy',
    Company_Website: 'Company_Website',
    Physical_Address: 'Physical_Address',
    Known_Customers: 'Known_Customers',
    State_Province_Region: 'State_Province_Region',
    '100%_Recycler_Scrap': '100%_Recycler_Scrap'
  };

  const columnOrder = Object.values(columnMapping);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [displayedRows, setDisplayedRows] = useState(5);

  const sortedData = useMemo(() => {
    let sortableItems = [...responseData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [responseData, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleLoadMore = () => {
    setDisplayedRows(displayedRows + 5);
  };

  // Sort company information based on checkerID
  const sortedCompanyInfo = useMemo(() => {
    return [...companyInfo].sort((a, b) => a.checkerId - b.checkerId);
  }, [companyInfo]);

  // Helper to get display color based on the notes
  const getColor = (notes) => {
    return notes === 'Complete' ? 'green.500' : notes === 'Error' ? 'red.500' : 'gray.500';
  };

  // CSV Download Function
  const downloadCsv = () => {

    let csvContent = [Object.values(columnMapping).join(",")].join("\n") // CSV header
      + "\n"
      + responseData.map(row =>
          Object.keys(columnMapping)
            .map(fieldName => JSON.stringify(row[fieldName] || '', null, 2))
            .join(",")
        ).join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "due_diligence_data.csv");
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the file
    link.remove();
    URL.revokeObjectURL(url); // Free up memory
  };

  // Enhanced company info display with structured headings
  return (
    <VStack spacing={4} align="stretch">
      <Box p={5} shadow="md" borderWidth="1px">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>Checker Worksheet Results</Text>
        <Text fontSize="sm" color="gray.600">
          The Checker worksheet ensures that all required information in the CMRT is complete. To use it, confirm that all mandatory fields are filled in (highlighted in green). If any fields are marked in red, review the accompanying "Notes" for necessary actions.
        </Text>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Question</Th>
              <Th>Answer Provided</Th>
              <Th>Notes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedCompanyInfo.map(info => (
              <Tr key={info.checkerId}>
                <Td fontWeight="bold" color={getColor(info.notes)}>{info.field}</Td>
                <Td>{info.value || ''}</Td>
                <Td color={getColor(info.notes)}>{info.notes || ''}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Text fontSize="2xl" fontWeight="bold" mb={4}>Due Diligence</Text>
      <Text fontSize="sm" color="gray.600">
        The table below integrates data from multiple sources, providing insights into critical control points within mineral and metal supply chains. This solution ensures users have up-to-date access to supplier feedback and smelter contact information, enhancing overall supply chain transparency.
      </Text>

      <Box overflowX="auto">
        <Table variant="simple">
          <Thead>
		    <Tr>
		      {columnOrder.slice(0, 12).map((columnName, idx) => (
		        <Th key={idx} onClick={() => requestSort(Object.keys(columnMapping).find(key => columnMapping[key] === columnName))}
		            style={{ cursor: 'pointer' }}>
		          {columnName}
		        </Th>
		      ))}
		    </Tr>
		  </Thead>
		  <Tbody>
		    {sortedData.slice(0, displayedRows).map((row, idx) => (
		      <Tr key={idx}>
		        {Object.keys(columnMapping).slice(0, 12).map((originalColumn, index) => (
		          <Td key={index}>
		            {row[originalColumn] ? row[originalColumn].toString() : ''}
		          </Td>
		        ))}
		      </Tr>
		    ))}
		  </Tbody>
        </Table>
      </Box>

      <Text>Displaying {displayedRows} of {sortedData.length} rows</Text>

      <HStack spacing={2}>
	    {displayedRows < sortedData.length && (
	      <>
	        <Button colorScheme="teal" onClick={handleLoadMore}>Load More</Button>
	        <Button colorScheme="teal" onClick={downloadCsv}>Download All</Button>
	      </>
	    )}
	    <Button colorScheme="teal" onClick={() => navigate('/smelter-rollup')}>Roll Up Smelters</Button>
	    <Button colorScheme="teal" onClick={() => navigate('/home')}>Back to Home</Button>
	  </HStack>
    </VStack>
  );
}

export default ResultsPage;
