import React from 'react';
import { Flex, IconButton, useDisclosure, Drawer, DrawerContent, Box, Link, Text, Stack } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'; // Import CloseIcon
import { useNavigate } from 'react-router-dom';

function Navbar({ isAuthenticated, showCMRT, showSmelterRollup }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  console.log('Navbar Props:', { isAuthenticated, showCMRT, showSmelterRollup });

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('showCMRT');
    localStorage.removeItem('showSmelterRollup');
    navigate('/home');
    window.location.reload();  // Reload to reset state
  };

  // Handle navigation and close the drawer
  const handleNavigation = (route) => {
    navigate(route);
    onClose(); // Close drawer after navigation
  };

  return (
    <>
      <Flex justifyContent={{ base: 'space-between', md: 'center' }} alignItems="center" p={4}>
        {/* Mobile Menu Icon */}
        <IconButton
          size="md"
          icon={<HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: 'none' }}
          onClick={onOpen}
        />

        {/* Desktop Links */}
        <Flex display={{ base: 'none', md: 'flex' }}>
          <Link onClick={() => handleNavigation('/home')} mr={6}>Home</Link>
          {/* Conditionally show CMRT and Smelter Rollup links if authenticated */}
          {isAuthenticated && showCMRT && <Link onClick={() => handleNavigation('/upload-cmrt')} mr={6}>Upload Templates</Link>}
          {isAuthenticated && showSmelterRollup && <Link onClick={() => handleNavigation('/smelter-rollup')} mr={6}>Smelter Rollup</Link>}
          <Link onClick={() => handleNavigation('/about')} mr={6}>About</Link>
          <Link onClick={() => handleNavigation('/contact')}>Contact Us</Link>
          {isAuthenticated && (
            <Link onClick={handleLogout} ml={6}>Logout</Link>
          )}
        </Flex>

        {/* Company Name for Mobile */}
        <Text fontSize="xl" display={{ base: 'flex', md: 'none' }}>CMRT AI</Text>
      </Flex>

      {/* Mobile Drawer */}
      <Drawer placement="top" onClose={onClose} isOpen={isOpen} size="full"> {/* Drawer drops from the top */}
        <DrawerContent>
          <Box p={4}>
            {/* Close Button */}
            <IconButton
              size="md"
              icon={<CloseIcon />} // Close button
              aria-label="Close Menu"
              onClick={onClose}
              mb={4}
            />

            <Stack spacing={4}> {/* Stack to ensure line breaks between links */}
              <Link onClick={() => handleNavigation('/home')}>Home</Link>
              {/* Conditionally show CMRT and Smelter Rollup links if authenticated */}
              {isAuthenticated && showCMRT && <Link onClick={() => handleNavigation('/upload-cmrt')}>Upload Templates</Link>}
              {isAuthenticated && showSmelterRollup && <Link onClick={() => handleNavigation('/smelter-rollup')}>Smelter Rollup</Link>}
              <Link onClick={() => handleNavigation('/about')}>About</Link>
              <Link onClick={() => handleNavigation('/contact')}>Contact Us</Link>
              {isAuthenticated && (
                <Link onClick={handleLogout}>Logout</Link>
              )}
            </Stack>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navbar;
