// src/components/SmelterRollupPage.js
import React from 'react';
import {
  Box,
  Button,
  Text,
  Heading,
  Container,
  Stack,
  useToast,
} from '@chakra-ui/react';

function SmelterRollupPage() {
  const toast = useToast();

  const handleBuildSmelterRollup = async () => {
    try {
      const response = await fetch('/api/build-smelter-rollup', {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'smelter_rollup.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      toast({
        title: 'Download Started',
        description: 'Your smelter rollup is downloading.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      toast({
        title: 'Error',
        description: 'There was a problem generating the smelter rollup.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="gray.50" py={10}>
      <Container maxW="container.md">
        <Stack spacing={6} textAlign="center">
          <Heading as="h1" size="2xl">
            Smelter Rollup
          </Heading>
          <Text fontSize="lg" lineHeight="tall">
            Smelter rollups include a comprehensive list of unique smelters listed in all uploaded CMRTs.
            We recommend that clients perform a smelter rollup after each CMRT upload, or at least weekly,
            to detect any issues early.
          </Text>
          <Button
            colorScheme="teal"
            size="lg"
            onClick={handleBuildSmelterRollup}
          >
            Build Smelter Rollup
          </Button>
        </Stack>
      </Container>
    </Box>
  );
}

export default SmelterRollupPage;

