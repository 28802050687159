// src/components/Contact.js
import React from 'react';
import { Box, Heading, Text, Container, Stack, Button, Link } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';

function Contact() {
  return (
    <Box bg="gray.50" py={10}>
      <Container maxW="container.md">
        <Stack spacing={6} textAlign="center">
          <Heading as="h1" size="2xl">
            Contact Us
          </Heading>
          <Text fontSize="lg" lineHeight="tall">
            We are welcoming early adopters as we expand our ecosystem. To participate in a pilot program or receive updates on upcoming events and product releases, please contact us at{' '}
            <Link href="mailto:sales@cmrt.ai" color="teal.500" fontWeight="bold">
              sales@cmrt.ai
            </Link>
           .
          </Text>
          <Button
            as="a"
            href="mailto:sales@cmrt.ai"
            colorScheme="teal"
            leftIcon={<EmailIcon />}
            size="lg"
          >
            Email Us
          </Button>
        </Stack>
      </Container>
    </Box>
  );
}

export default Contact;

