// src/components/MainPageContent.js
import React, { useState } from 'react';
import {
  VStack,
  Text,
  Button,
  Heading,
  Image,
  Alert,
  AlertIcon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Link as ChakraLink,
  Box,
  Container,
  Stack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Access the maintenance mode environment variable
const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

function MainPageContent({ onLoginSuccess, isAuthenticated }) {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal controls
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [twoFaRequired, setTwoFaRequired] = useState(false); // To track if 2FA is required
  const [twoFaCode, setTwoFaCode] = useState(''); // To store 2FA code
  const [qrCode, setQrCode] = useState(null); // To display QR code for 2FA setup
  const [showCMRT, setShowCMRT] = useState(false);
  const [showSmelterRollup, setShowSmelterRollup] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  // State variable to control the Maintenance Mode modal
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);

  // Custom handler for Login button click
  const handleLoginClick = () => {
    if (maintenanceMode) {
      setShowMaintenanceModal(true);
    } else {
      onOpen(); // Open the login modal as usual
    }
  };

  // Handle login submission
  const handleLogin = async () => {
    try {
      const response = await axios.post(
        '/api/login',
        { email, password },
        { withCredentials: true }
      );

      if (response.data.two_fa) {
        setTwoFaRequired(true);
        setError(null);

        if (response.data.require_qr_code) {
          fetchQrCode();
        }
      } else if (response.data.message === 'Login successful') {
        setShowCMRT(response.data.show_cmrt);
        setShowSmelterRollup(response.data.show_smelter_rollup);
        setError(null);
        onLoginSuccess(response.data.show_cmrt, response.data.show_smelter_rollup);
        onClose(); // Close modal after successful login
      }
    } catch (error) {
      setError('Login failed. Please check your credentials.');
    }
  };

  // Handle sending reset password email
  const handleResetPassword = async () => {
    try {
      const response = await axios.post('/api/reset-password', { email });
      if (response.data.message === 'Password reset email sent') {
        setResetEmailSent(true);
        setError(null);
      }
    } catch (error) {
      setError('Failed to send reset email. Please verify that the email address is correct.');
    }
  };

  // Fetch QR Code for 2FA setup
  const fetchQrCode = async () => {
    try {
      const response = await axios.get('/api/setup-2fa', {
        responseType: 'blob',
        withCredentials: true,
      });

      const contentType = response.headers['content-type'];

      if (contentType && contentType.includes('application/json')) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const jsonResponse = JSON.parse(reader.result);
            if (jsonResponse.qr_code_needed === false) {
              setQrCode(null);  // No QR code needed, but 2FA is required
            } else {
              setError('Unexpected response from server.');
            }
          } catch (e) {
            setError('Error parsing server response.');
          }
        };
        reader.readAsText(response.data);
      } else if (contentType && contentType.includes('image/png')) {
        const imageObjectURL = URL.createObjectURL(response.data);
        setQrCode(imageObjectURL);  // QR code required and available
      } else {
        setError('Unexpected content type from server.');
      }

    } catch (error) {
      setError('Error fetching QR code for 2FA setup.');
    }
  };

  // Handle 2FA code verification
  const handleVerify2FA = async () => {
    try {
      const response = await axios.post(
        '/api/verify-2fa',
        { two_fa_code: twoFaCode },
        { withCredentials: true }
      );

      if (response.data.message === '2FA verified') {
        setShowCMRT(response.data.show_cmrt);
        setShowSmelterRollup(response.data.show_smelter_rollup);
        setError(null);
        onLoginSuccess(response.data.show_cmrt, response.data.show_smelter_rollup);
        onClose(); // Close modal after successful 2FA verification
      } else {
        setError('Invalid 2FA code.');
      }
    } catch (error) {
      setError('Error verifying 2FA code.');
    }
  };

  return (
    <Box bg="gray.50" py={10}>
      <Container maxW="container.md">
        <Stack spacing={8} textAlign="center" align="center">
          <Image
            src="/images/global_analytics.png"
            alt="Global Analytics"
            maxW={{ base: '90%', md: '70%' }}
            mb={4}
          />
          <Heading as="h1" size="2xl">
            CMRT AI
          </Heading>
          <Text fontSize="xl" maxW="600px">
            Transforming supplier data into actionable insights at unprecedented speed and scale.
          </Text>

          {/* Button to open Login Modal */}
          {!isAuthenticated && (
            <Button colorScheme="teal" size="lg" onClick={handleLoginClick}>
              Login
            </Button>
          )}
        </Stack>
      </Container>

      {/* Login Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{twoFaRequired ? 'Verify Your Identity' : 'Welcome back'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {twoFaRequired ? (
              <>
                <FormControl id="twoFaCode" isRequired>
                  <FormLabel>Check your preferred authenticator for a code</FormLabel>
                  <Input
                    type="text"
                    placeholder="Enter your one-time code"
                    value={twoFaCode}
                    onChange={(e) => setTwoFaCode(e.target.value)}
                  />
                </FormControl>

                {/* Conditionally display the QR code for users who need to set up 2FA */}
                {qrCode ? (
                  <Box mt={4} textAlign="center">
                    <Text mb={2}>
                      Scan this QR code with your preferred authenticator app (e.g., Google or Microsoft Authenticator) to set up two-factor authentication.
                    </Text>
                    <Image src={qrCode} alt="2FA QR code" display="inline-block" />
                  </Box>
                ) : null}

                {error && (
                  <Alert status="error" mt={4}>
                    <AlertIcon />
                    {error}
                  </Alert>
                )}
              </>
            ) : (
              <>
                <FormControl id="email" isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>

                <FormControl id="password" isRequired mt={4}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>

                {error && (
                  <Alert status="error" mt={4}>
                    <AlertIcon />
                    {error}
                  </Alert>
                )}

                {/* Add "Don't have an account? Email Us" above the Reset Password link */}
                <Text mt={4}>
                  Don't have an account?{' '}
                  <ChakraLink color="teal.500" href="mailto:sales@cmrt.ai">
                    Email Us
                  </ChakraLink>
                </Text>

                {/* Reset Password link */}
                <ChakraLink color="teal.500" mt={2} onClick={handleResetPassword}>
                  Reset Password
                </ChakraLink>

                {resetEmailSent && (
                  <Alert status="success" mt={2}>
                    <AlertIcon />
                    Password reset email sent. Check your inbox.
                  </Alert>
                )}
              </>
            )}
          </ModalBody>

          <ModalFooter>
            {twoFaRequired ? (
              <Button colorScheme="teal" onClick={handleVerify2FA}>
                Continue
              </Button>
            ) : (
              <Button colorScheme="teal" onClick={handleLogin}>
                Login
              </Button>
            )}
            <Button variant="ghost" ml={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Maintenance Mode Modal */}
      <Modal isOpen={showMaintenanceModal} onClose={() => setShowMaintenanceModal(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Maintenance Notice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              We are performing nightly builds to roll out new functionality. Please return tomorrow at 10:00 AM PDT to log in.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={() => setShowMaintenanceModal(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MainPageContent;
