import React from 'react';
import MainPageContent from './components/MainPageContent';  // Assuming this is where the login happens

function HomePage({ onLoginSuccess, isAuthenticated }) {
  return (
    <div>
      <MainPageContent onLoginSuccess={onLoginSuccess} isAuthenticated={isAuthenticated} />
    </div>
  );
}

export default HomePage;

