// src/components/UploadCMRTPage.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  Input,
  useToast,
  Container,
  Stack,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function UploadCMRTPage() {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast({
        title: 'No File Selected',
        description: 'Please select a file to upload.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('/api/upload-cmrt', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (response.ok) {
        toast({
          title: 'Upload Successful',
          description: result.results,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/results', {
          state: {
            responseData: result.data,
            companyInfo: result.companyInfo,
          },
        });
      } else {
        throw new Error(result.results || 'An error occurred');
      }
    } catch (error) {
      toast({
        title: 'Upload Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error uploading file:', error);
    }
  };

  return (
    <Box bg="gray.50" py={10}>
      <Container maxW="container.md">
        <Stack spacing={6} textAlign="center">
          <Heading as="h1" size="2xl">
            Upload Templates
          </Heading>
          <Text fontSize="lg" lineHeight="tall">
            Please upload your Conflict Minerals Reporting Template (CMRT), Extended Minerals Reporting Template (EMRT), or Additional Minerals Reporting Template (AMRT) to begin the analysis.
          </Text>
          <Stack spacing={4} direction="column" align="center">
            <Input
              type="file"
              onChange={handleFileChange}
              accept=".xls,.xlsx"
              variant="filled"
              size="md"
              width="auto"
            />
            <Button
              colorScheme="teal"
              size="lg"
              onClick={handleFileUpload}
            >
              Upload File
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default UploadCMRTPage;

