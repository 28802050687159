import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';  // Assuming this is your Navbar component
import HomePage from './HomePage';  // Assuming this includes MainPageContent
import UploadCMRTPage from './components/UploadCMRTPage';
import SmelterRollupPage from './components/SmelterRollupPage';
import ResultsPage from './ResultsPage';
import About from './components/About';
import Contact from './components/Contact';
import ResetPasswordForm from './components/ResetPasswordForm';
import LandingPage from './components/LandingPage';
import { useState, useEffect } from 'react';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showCMRT, setShowCMRT] = useState(false);
  const [showSmelterRollup, setShowSmelterRollup] = useState(false);
  const navigate = useNavigate();  // For programmatic navigation

  // Fetch authentication and access rights from localStorage on component mount
  useEffect(() => {
    const savedAuth = localStorage.getItem('isAuthenticated') === 'true';
    const savedShowCMRT = localStorage.getItem('showCMRT') === 'true';
    const savedShowSmelterRollup = localStorage.getItem('showSmelterRollup') === 'true';

    setIsAuthenticated(savedAuth);
    setShowCMRT(savedShowCMRT);
    setShowSmelterRollup(savedShowSmelterRollup);
  }, []);

  // Handle successful login
  const handleLoginSuccess = (cmrtAccess, smelterAccess) => {
    console.log('Login successful:');
    console.log('isAuthenticated:', true);
    console.log('CMRT Access:', cmrtAccess);
    console.log('Smelter Rollup Access:', smelterAccess);

    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('showCMRT', cmrtAccess);
    localStorage.setItem('showSmelterRollup', smelterAccess);

    setIsAuthenticated(true);
    setShowCMRT(cmrtAccess);
    setShowSmelterRollup(smelterAccess);

    // Use a small timeout to ensure the state updates before navigation
    setTimeout(() => {
      navigate('/upload-cmrt');
    }, 100);
  };

  return (
    <ChakraProvider>
      <div className="App">
        {/* Pass the authentication and group-based props to the Navbar */}
        <Navbar
          isAuthenticated={isAuthenticated}
          showCMRT={showCMRT}
          showSmelterRollup={showSmelterRollup}
        />
        <Routes>
          <Route path="/" element={<LandingPage />} /> {/* Landing page as the default route */}
          <Route path="/home" element={<HomePage onLoginSuccess={handleLoginSuccess} isAuthenticated={isAuthenticated} />} /> {/* Home route */}
          <Route path="/about" element={<About />} /> {/* About route */}
          <Route path="/contact" element={<Contact />} /> {/* Contact route */}
          <Route path="/results" element={<ResultsPage />} /> {/* Results route */}
          <Route path="/upload-cmrt" element={<UploadCMRTPage />} />
          <Route path="/smelter-rollup" element={<SmelterRollupPage />} />
          <Route path="/reset/:token" element={<ResetPasswordForm />} /> {/* Tokenized reset route */}
        </Routes>
      </div>
    </ChakraProvider>
  );
}

export default App;


