import React, { useRef, useState } from 'react';
import {
  Box,
  Heading,
  Button,
  VStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

function LandingPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const toast = useToast();
  const recaptchaV2Ref = useRef(null);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    } else {
      setEmailError('');
    }

    if (!recaptchaV2Ref.current.getValue()) {
      setEmailError('Please complete the CAPTCHA.');
      return;
    }

    try {
      const response = await axios.post('https://www.cmrt.ai/api/subscribe', {
        email,
        recaptchaToken: recaptchaV2Ref.current.getValue(),
        recaptchaVersion: 'v2',
      });

      if (response.status === 200) {
        onClose();
        toast({
          title: 'Subscription Initiated',
          description: 'Please check your email to confirm your subscription.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setEmail(''); // Clear email input
        recaptchaV2Ref.current.reset(); // Reset reCAPTCHA widget
      } else {
        throw new Error('Subscription failed.');
      }
    } catch (error) {
      toast({
        title: 'Subscription Failed',
        description: error.response?.data?.error || 'Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box position="relative" minHeight="100vh" overflow="hidden">
      {/* Background Video */}
      <Box
        as="video"
        autoPlay
        muted
        loop
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        objectFit="cover"
        zIndex="-1"
      >
        <source src="/images/857251-hd_1620_1080_25fps.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
      </Box>

      {/* Content Overlay */}
      <VStack
        spacing={8}
        position="relative"
        zIndex="1"
        color="white"
        justify="center"
        textAlign="center"
        px={4}
        py={10}
        fontFamily="'Poppins', sans-serif"
      >
        {/* Top Section - Launching Soon */}
        <Heading
          as="h1"
          size="lg"
          fontWeight="400"
          letterSpacing="widest"
          textTransform="uppercase"
        >
          Navigate Conflict Minerals Compliance with Ease—Join Us Now
        </Heading>

        {/* Subscribe Button */}
        <Button
          colorScheme="whiteAlpha"
          variant="outline"
          size="lg"
          onClick={onOpen}
          borderColor="white"
          color="white"
          _hover={{ bg: 'whiteAlpha.300' }}
          fontWeight="500"
          letterSpacing="wide"
          px={8}
        >
          Subscribe
        </Button>
      </VStack>

      {/* Subscribe Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.900" color="white" fontFamily="'Poppins', sans-serif">
          <ModalHeader>Subscribe</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="email" isRequired isInvalid={emailError !== ''}>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                bg="gray.800"
                borderColor="gray.600"
              />
              {emailError && (
                <FormErrorMessage>{emailError}</FormErrorMessage>
              )}
            </FormControl>

            {/* reCAPTCHA v2 Widget */}
            <Box mt={4}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
                ref={recaptchaV2Ref}
                theme="dark"
              />
            </Box>

            <Button
              colorScheme="whiteAlpha"
              onClick={handleSubscribe}
              mt={4}
              width="100%"
              fontWeight="500"
              letterSpacing="wide"
            >
              Submit
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default LandingPage;
