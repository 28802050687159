// src/components/About.js
import React from 'react';
import { Box, Heading, Text, Container, Stack } from '@chakra-ui/react';

function About() {
  return (
    <Box bg="gray.50" py={10}>
      <Container maxW="container.md">
        <Stack spacing={6}>
          <Heading as="h1" size="2xl" textAlign="center">
            About Us
          </Heading>
          <Text fontSize="lg" lineHeight="tall">
            We are a team of regulatory compliance and AI specialists dedicated to helping businesses navigate conflict minerals compliance through innovative AI solutions. With over 80 years of combined expertise, we have developed an AI platform that offers a comprehensive approach to managing the technical and legal challenges of today’s regulatory landscape.
          </Text>
        </Stack>
      </Container>
    </Box>
  );
}

export default About;

